import { AxiosError } from "axios";
import { BroadcastService } from "modules/broadcast-issue/services/BroadcastService";
import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";
import { PutBanner } from "../actions/actionTypes";
import {
  getBannerError,
  getBannerSuccess,
  putBannerError,
  putBannerSuccess,
} from "../actions/actions";

const broadcastService = BroadcastService.getInstance();

export function* setBanner(action: PutBanner): SagaIterator {
  try {
    const { data } = yield call(broadcastService.putBanner, action.payload);

    action.payload.onSuccess();
    yield put(putBannerSuccess(data));
  } catch (e) {
    yield put(putBannerError((e as AxiosError).response?.data));
  }
}

export function* getBanner(): SagaIterator {
  try {
    const { data } = yield call(broadcastService.getBanner);

    yield put(getBannerSuccess(data));
  } catch (e) {
    yield put(getBannerError((e as AxiosError).response?.data));
  }
}
