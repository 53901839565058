import { isEmpty } from "lodash";

import { RegionalManagement, City } from "modules/region-management/models";
import { RegionalManagementActionTypes } from "modules/region-management/store/actions/actions";
import { RegionalManagementActions } from "modules/region-management/store/actions/actionTypes";

const initialState: RegionalManagement = {
  isFetching: false,
  noDataMessage: "",
  errorMessages: [],
  countries: [],
  cities: [],
  languages: [],
  city: null,
};

const regionalManagementReducer = (state: RegionalManagement = initialState, action: RegionalManagementActions): RegionalManagement => {
  switch (action.type) {
  case RegionalManagementActionTypes.FETCH_COUNTRIES:
  case RegionalManagementActionTypes.CREATE_COUNTRY:
  case RegionalManagementActionTypes.FETCH_LANGUAGES:
  case RegionalManagementActionTypes.FETCH_CITIES:
  case RegionalManagementActionTypes.FETCH_SINGLE_CITY:
  case RegionalManagementActionTypes.CREATE_CITY:
  case RegionalManagementActionTypes.REMOVE_CITY:
  case RegionalManagementActionTypes.EDIT_CITY: {
    return {
      ...state,
      isFetching: true,
      errorMessages: [],
      noDataMessage: "",
    };
  }

  case RegionalManagementActionTypes.FETCH_COUNTRIES_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      countries: action.payload,
      noDataMessage: isEmpty(action.payload) ? "There are no countries yet!" : "",
    };
  }

  case RegionalManagementActionTypes.CREATE_COUNTRY_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
    };
  }

  case RegionalManagementActionTypes.FETCH_CITIES_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      cities: action.payload,
      noDataMessage: isEmpty(action.payload) ? "There are no cities yet!" : "",
    };
  }

  case RegionalManagementActionTypes.CREATE_CITY_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      cities: [
        ...state.cities,
        action.payload,
      ],
    };
  }

  case RegionalManagementActionTypes.REMOVE_CITY_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      cities: state.cities.filter((item: City) => Number(item.id) !== Number(action.payload.city_id)),
    };
  }

  case RegionalManagementActionTypes.EDIT_CITY_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      cities: state.cities.map((item: City) => Number(item.id) === Number(action.payload.id) ? ({ ...item, ...action.payload }) : item),
    };
  }

  case RegionalManagementActionTypes.FETCH_SINGLE_CITY_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      city: action.payload,
    };
  }

  case RegionalManagementActionTypes.CLEAN_SINGLE_CITY: {
    return {
      ...state,
      city: null,
    };
  }

  case RegionalManagementActionTypes.FETCH_LANGUAGES_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      languages: action.payload,
      noDataMessage: isEmpty(action.payload) ? "There are no languages yet!" : "",
    };
  }

  case RegionalManagementActionTypes.FETCH_COUNTRIES_ERROR:
  case RegionalManagementActionTypes.CREATE_COUNTRY_ERROR:
  case RegionalManagementActionTypes.FETCH_CITIES_ERROR:
  case RegionalManagementActionTypes.FETCH_SINGLE_CITY_ERROR:
  case RegionalManagementActionTypes.CREATE_CITY_ERROR:
  case RegionalManagementActionTypes.REMOVE_CITY_ERROR:
  case RegionalManagementActionTypes.FETCH_LANGUAGES_ERROR:
  case RegionalManagementActionTypes.EDIT_CITY_ERROR: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [
        ...state.errorMessages,
        ...action.payload,
      ],
    };
  }

  default:
    return state;
  }
};

export default regionalManagementReducer;
