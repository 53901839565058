import { Auth } from "modules/auth/models";

export default {
  user: null,
  isFetching: false,
  isLeadooAdmin: null,
  errorMessages: [],
  companyUser: null,
  name: "",
} as Auth;
