import { Auth } from "modules/auth/models";
import { AuthActionTypes } from "modules/auth/store/actions/actions";
import { AuthActions } from "modules/auth/store/actions/actionTypes";
import initState from "modules/auth/store/initialState";
import { InternalRole } from "modules/company-management/utils/types";

const authReducer = (state: Auth = initState, action: AuthActions): Auth => {
  switch (action.type) {
  case AuthActionTypes.SIGN_IN:
  case AuthActionTypes.SIGN_OUT:
  case AuthActionTypes.FETCH_ME_OAUTH:
  case AuthActionTypes.FETCH_ME_USER:
  case AuthActionTypes.SEND_EMAIL:
  case AuthActionTypes.RESTORE_PASSWORD:
  case AuthActionTypes.FETCH_USER_BY_CODE: {
    return {
      ...state,
      isFetching: true,
      isLeadooAdmin: null,
      errorMessages: [],
    };
  }

  case AuthActionTypes.SIGN_IN_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
    };
  }

  case AuthActionTypes.FETCH_USER_BY_CODE_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      user: action.payload.user,
    };
  }

  case AuthActionTypes.SIGN_OUT_SUCCESS:
  case AuthActionTypes.SEND_EMAIL_SUCCESS:
  case AuthActionTypes.RESTORE_PASSWORD_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      user: null,
    };
  }

  case AuthActionTypes.FETCH_ME_OAUTH_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      isLeadooAdmin: action.payload.roles.internal.includes(InternalRole.LEADOO_ADMIN),
      name: action.payload.name
    };
  }

  case AuthActionTypes.FETCH_ME_USER_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      user: action.payload,
    };
  }

  case AuthActionTypes.SIGN_IN_ERROR:
  case AuthActionTypes.SIGN_OUT_ERROR:
  case AuthActionTypes.FETCH_ME_OAUTH_ERROR:
  case AuthActionTypes.FETCH_ME_USER_ERROR:
  case AuthActionTypes.SEND_EMAIL_ERROR:
  case AuthActionTypes.RESTORE_PASSWORD_ERROR:
  case AuthActionTypes.FETCH_USER_BY_CODE_ERROR: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [
        ...state.errorMessages,
        ...action.payload,
      ],
    };
  }

  default:
    return state;
  }
};

export default authReducer;
