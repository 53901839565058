import { isEmpty } from "lodash";

import { FreeManagement } from "modules/free-users/models/store/FreeManagement";
import { FreeUsersActions }  from "modules/free-users/store/actions/actionTypes";
import {
  FreeUsersActionsTypes,
} from "modules/free-users/store/actions/actions";

const initialState: FreeManagement = {
  isFetching: false,
  isModulesFetching: false,
  errorMessages: [],
  successMessage: "",
  noDataMessage: "",
  companies: [],
  company: null,
  createdCompanyId: null,
  currencies: [],
  churnTypes: [],
  bots: [],
  mediapartners: [],
  totalItems: 0,
  totalPages: 1,
  currentPage: 1,
  clearbit: null,
  salesPeople: [],
  contracts: [],
  industries: [],
  industrySubClasses: [],
  loginUrl: "",
  isContractModalVisible: false,
  adminLogs: {} as any,
  platforms: [],
  links: null,
  histories: [],
  hasMoreHistories: true,
};

export default function freeUsersReducer(state: FreeManagement = initialState, action: FreeUsersActions): FreeManagement {
  switch (action.type) {
  case FreeUsersActionsTypes.FETCH_FREE_ACCOUNT_DETAILS:
  case FreeUsersActionsTypes.FETCH_FREE_USERS:
  case FreeUsersActionsTypes.LOGIN_FREE_ACCOUNT:
  case FreeUsersActionsTypes.TOGGLE_ACTIVATION_FREE_ACCOUNT:
  case FreeUsersActionsTypes.FETCH_CHURN_TYPE_FREE_ACCOUNT:
    return {
      ...state,
      isFetching: true,
      errorMessages: [],
      noDataMessage: "",
      successMessage: "",
    };

  case FreeUsersActionsTypes.EDIT_FREE_MODULES: {
    return {
      ...state,
      isModulesFetching: true,
      errorMessages: [],
    };
  }

  case FreeUsersActionsTypes.CREATE_FREE_ACCOUNT: {
    return {
      ...state,
      isFetching: true,
      errorMessages: [],
      company: action.payload.newFreeAccount
    };
  }

  case FreeUsersActionsTypes.EDIT_FREE_ACCOUNT: {
    return {
      ...state,
      isFetching: true,
      errorMessages: [],
      company: {
        ...state.company,
        ...action.payload.updatedCompany,
      },
    };
  }

  case FreeUsersActionsTypes.DELETE_FREE_ACCOUNT: {
    return {
      ...state,
      isFetching: true,
      errorMessages: [],
    };
  }

  case FreeUsersActionsTypes.FETCH_FREE_USERS_SUCCESS:
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      noDataMessage: isEmpty(action.payload.items) ?
        "There are no free users yet!" : "",
      companies: action.payload.items,
      currentPage: Number(action.payload!.meta.currentPage),
      totalPages: Number(action.payload!.meta.totalPages),
      totalItems: Number(action.payload!.meta.totalItems),
      successMessage: "Data fetched successful!",
      links: action.payload.links,
    };

  case FreeUsersActionsTypes.FETCH_FREE_ACCOUNT_DETAILS_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      company: isEmpty(action.payload.company) ? null :
        action.payload.company,
    };
  }

  case FreeUsersActionsTypes.CREATE_FREE_ACCOUNT_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      createdCompanyId: action.payload.newAccountId,
    };
  }

  case FreeUsersActionsTypes.EDIT_FREE_MODULES_SUCCESS: {
    return {
      ...state,
      isModulesFetching: false,
      errorMessages: [],
      companies: state.companies.map(
        company => company.id === Number(action.payload.data.companyId) ?
          ({
            ...company,
            module: company.module ? {
              ...company.module,
              [action.payload.data.key]: action.payload.data.value,
            } : null,
          }) : company),
    };
  }

  case FreeUsersActionsTypes.EDIT_FREE_ACCOUNT_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
    };
  }

  case FreeUsersActionsTypes.DELETE_FREE_ACCOUNT_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
    };
  }

  case FreeUsersActionsTypes.LOGIN_FREE_ACCOUNT_SUCCESS: {
    if (action.payload.data.length !== 0) {
      window.open(action.payload.data, "_blank");
    }
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      loginUrl: action.payload.data,
    };
  }

  case FreeUsersActionsTypes.TOGGLE_ACTIVATION_FREE_ACCOUNT_SUCCESS: {
    const deactivatedCompany = state.companies.filter(company => company.id === action.payload.id)[0];

    return {
      ...state,
      company: {
        ...state.company!,
        is_active: action.payload.is_active
      },
      companies: [
        ...state.companies,
        {
          ...deactivatedCompany,
          is_active: action.payload.is_active
        }
      ],
      isFetching: false,
      errorMessages: [],
    };
  }

  case FreeUsersActionsTypes.FETCH_CHURN_TYPE_FREE_ACCOUNT_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      churnTypes: action.payload,
    };
  }

  case FreeUsersActionsTypes.EDIT_FREE_ACCOUNT_ERROR:
  case FreeUsersActionsTypes.EDIT_FREE_MODULES_ERROR:
  case FreeUsersActionsTypes.FETCH_FREE_USERS_ERROR:
  case FreeUsersActionsTypes.FETCH_FREE_ACCOUNT_DETAILS_ERROR:
  case FreeUsersActionsTypes.CREATE_FREE_ACCOUNT_ERROR:
  case FreeUsersActionsTypes.DELETE_FREE_ACCOUNT_ERROR:
  case FreeUsersActionsTypes.LOGIN_FREE_ACCOUNT_ERROR:
  case FreeUsersActionsTypes.TOGGLE_ACTIVATION_FREE_ACCOUNT_ERROR:
  case FreeUsersActionsTypes.FETCH_CHURN_TYPE_FREE_ACCOUNT_ERROR: {
    const errors = typeof action.payload.message === "string" ? [action.payload.message] : [...Object.values(action.payload.errors).map(msg => msg[0])];

    return {
      ...state,
      isFetching: false,
      isModulesFetching: false,
      companies: [],
      company: null,
      createdCompanyId: null,
      successMessage: "",
      noDataMessage: "",
      errorMessages: [
        ...state.errorMessages,
        ...errors
      ],
    };
  }
    
  default:
    return state;
  }
}
