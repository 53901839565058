export enum IntegrationsErrorsActionsTypes {
    FETCH_ERRORS_LIST = "[INTEGRATIONS_ERRORS_MODULE] FETCH_ERRORS_LIST",
    FETCH_ERRORS_LIST_SUCCESS = "[INTEGRATIONS_ERRORS_MODULE] FETCH_ERRORS_LIST_SUCCESS",
    FETCH_ERRORS_LIST_ERROR = "[INTEGRATIONS_ERRORS_MODULE] FETCH_ERRORS_LIST_ERROR",
    ERRORS_LIST_SET_IS_LOADING = "[INTEGRATIONS_ERRORS_MODULE] ERRORS_LIST_SET_IS_LOADING",
    ERRORS_LIST_SET_TOTAL_COUNT = "[INTEGRATIONS_ERRORS_MODULE] ERRORS_LIST_SET_TOTAL_COUNT",

    UPDATE_INTEGRATION_ERROR = "[INTEGRATIONS_ERRORS_MODULE] UPDATE_INTEGRATION_ERROR",
    UPDATE_INTEGRATION_ERROR_ERROR = "[INTEGRATIONS_ERRORS_MODULE] UPDATE_INTEGRATION_ERROR_ERROR",
    UPDATE_INTEGRATION_ERROR_IS_LOADING = "[INTEGRATIONS_ERRORS_MODULE] UPDATE_INTEGRATION_ERROR_IS_LOADING",
}
