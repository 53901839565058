import {FetchIntegrationsErrorsQueryParams, IntegrationError, UpdateIntegrationErrorPayload} from "../../types";
import {IntegrationsErrorsActionsTypes as c} from "./actions";

export const fetchIntegrationsErrors = (params: FetchIntegrationsErrorsQueryParams) => ({
  type: c.FETCH_ERRORS_LIST,
  payload: params,
});

export const fetchIntegrationsErrorsSuccess = (params: IntegrationError[]) => ({
  type: c.FETCH_ERRORS_LIST_SUCCESS,
  payload: params,
});

export const fetchIntegrationsErrorsError = (params: string) => ({
  type: c.FETCH_ERRORS_LIST_ERROR,
  payload: params,
});

export const setIntegrationsErrorsIsLoading = (params: boolean) => ({
  type: c.ERRORS_LIST_SET_IS_LOADING,
  payload: params,
});

export const setIntegrationsErrorsTotalCount = (params: number) => ({
  type: c.ERRORS_LIST_SET_TOTAL_COUNT,
  payload: params,
});


export const updateIntegrationsError = (params: UpdateIntegrationErrorPayload) => ({
  type: c.UPDATE_INTEGRATION_ERROR,
  payload: params,
});

export const updateIntegrationsErrorError = (params: string) => ({
  type: c.UPDATE_INTEGRATION_ERROR_ERROR,
  payload: params,
});

export const updateIntegrationsErrorIsLoading = (params: boolean) => ({
  type: c.UPDATE_INTEGRATION_ERROR_IS_LOADING,
  payload: params,
});
