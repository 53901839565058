import { AxiosResponse } from "axios";

import { BaseService } from "libs/services";
import { FreeAccount, CompanyChurnData } from "libs/models";
import { QueryParams } from "modules/free-users/utils/types";

export class FreeUsersService extends BaseService {
  private FREE_USERS_API_ROUTE = this.ADMIN_API_HOST;

  static _instance: FreeUsersService;

  constructor() {
    super();

    if (FreeUsersService._instance) {
      throw Error("Unable to create new instance!");
    }
  }

  static getInstance(): FreeUsersService {
    if (!FreeUsersService._instance) {
      FreeUsersService._instance = new FreeUsersService();
    }

    return FreeUsersService._instance;
  }

  public fetchUsers = async (params?: QueryParams): Promise<AxiosResponse> => {
    let queryParams: object = {};

    queryParams= { ...queryParams, is_free: 1, is_active: 1, };
    if (params && params!.is_active !== undefined && String(params!.is_active) === "3") {
      delete params.is_active;

      queryParams = {
        ...params,
        saler_id: "null",
      };
    } else {
      queryParams = {...queryParams, ...params,  };
    }

    const res = await this.get(`${this.FREE_USERS_API_ROUTE}/company/v2`, { params: queryParams });

    return res;
  };

  public fetchAccountDetails = async (companyId: number): Promise<AxiosResponse> => {
    return this.get(`${this.FREE_USERS_API_ROUTE}/company/${companyId}`);
  };

  public createAccount = async (freeUser: FreeAccount): Promise<AxiosResponse> => {
    return this.post(`${this.FREE_USERS_API_ROUTE}/company/lsa`, freeUser);
  };

  public editAccount = async (freeUser: FreeAccount): Promise<AxiosResponse> => {
    return this.put(`${this.FREE_USERS_API_ROUTE}/company/lsa/${freeUser.id}`, freeUser);
  };

  public editCompanyModules = ({ key, value, companyId }: { key: string; value: number; companyId: number }): Promise<AxiosResponse> => {
    return this.put(`${this.FREE_USERS_API_ROUTE}/module/${companyId}`, { key, value });
  };

  public deleteAccount = async (companyId: number): Promise<AxiosResponse> => {
    return this.delete(`${this.FREE_USERS_API_ROUTE}/company/${companyId}`);
  };

  public loginWithCompany = (companyId: number): Promise<AxiosResponse> => this.get(`${this.FREE_USERS_API_ROUTE}/company/login/${companyId}`);

  public toggleCompanyActivated = ({ id, churn }: { id: number; churn?: CompanyChurnData }): Promise<AxiosResponse> => {
    return this.patch(`${this.FREE_USERS_API_ROUTE}/company/activate/${id}`, churn);
  };

  public fetchChurnTypes = (): Promise<AxiosResponse> => {
    return this.get(`${this.FREE_USERS_API_ROUTE}/churn`);
  };
}
