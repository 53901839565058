import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { AuthService } from "modules/auth/services";

interface Props {
  children: React.ReactElement | React.FC;
}

export const NonAuthMiddleware: React.FC<Props> = ({ children }) => {
  const history = useHistory();

  useEffect(() => {
    if (AuthService.getAuthState()) {
      history.push("/");
    }
  }, []);

  return (
    <>
      {children}
    </>
  );
};
