import { SagaIterator } from "redux-saga";
import { all, takeLatest, takeEvery } from "redux-saga/effects";

import { MediaPartnerBotActionTypes } from "modules/media-partner-bot/store/actions/actions";
import { fetchBotsWorker, fetchSingleBotWorker, shareBotWorker } from "./media-partner-bot-saga";

export default function* mediaPartnerBotWatcher(): SagaIterator {
  yield all([
    takeLatest(MediaPartnerBotActionTypes.FETCH_BOTS, fetchBotsWorker),
    takeLatest(MediaPartnerBotActionTypes.FETCH_SINGLE_BOT, fetchSingleBotWorker),
    takeEvery(MediaPartnerBotActionTypes.SHARE_BOT, shareBotWorker),
  ]);
}
