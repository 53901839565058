import { Option } from "libs/utils/types";

export type BlankPlatform = {
  id: number;
  code: string;
  platform_value: string;
};

export interface CompanyRole {
  internal: Array<InternalRole>;
  custom: Array<string>;
}

export enum InternalRole {
  CLIENT_OWNER = "user_owner",
  CLIENT_ADMIN = "user_admin",
  CLIENT_MEMBER = "user_member",
  CLIENT_AGENT = "user_agent",
  LEADOO_ADMIN = "leadoo_admin",
  LEADOO_STAFF = "leadoo_staff",
}

export type LimitOffsetParams = {
  limit?: number;
  offset?: number;
};

export enum ModalActions {
  SEND_CONTRACT = "sendContract",
  MAKE_ACTIVE = "makeActive",
  DELETE = "delete",
}

export type QueryParams = {
  is_active?: string;
  is_free?: number;
  saler_id?: number;
  country_id?: number;
  email?: string;
  name?: string;
  page?: number;
  limit?: number;
  no_redirect?: boolean;
};

export enum RoleTitle {
  ADMIN = "Admin",
  AGENT = "Agent",
  CUSTOM = "Custom Role",
  MEMBER = "Member",
  OWNER = "Owner",
}

export type SearchCompanyOptions = {
  is_active: string;

  country_id: string;

  saler_id: string;

  nameOrEmail: string;

  industry: string;
};

export interface SwitchSelectOption {
  title: RoleTitle | null;
  internal_slug: InternalRole | null;
  custom_slug: string | null;
  users: Array<Option>;
}
