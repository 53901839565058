import { AxiosResponse } from "axios";
import { BaseService } from "libs/services";
import { IssueType } from "../enums";

export interface BannerPayload {
  message: string;
  is_enable: number;
  state: IssueType;
}

export class BroadcastService extends BaseService {
  static _instance: BroadcastService;

  constructor() {
    super();

    if (BroadcastService._instance) {
      throw Error("Unable to create new instance!");
    }
  }

  static getInstance(): BroadcastService {
    if (!BroadcastService._instance) {
      BroadcastService._instance = new BroadcastService();
    }

    return BroadcastService._instance;
  }

  public putBanner = async (payload: BannerPayload): Promise<AxiosResponse> => {
    return this.put(`${this.ADMIN_API_HOST}/banner/`, payload);
  };

  public getBanner = async (): Promise<AxiosResponse> => {
    return this.get(`${this.ADMIN_API_HOST}/banner/`);
  };
}
