import { SagaIterator } from "redux-saga";
import { all, takeLatest, takeEvery } from "redux-saga/effects";

import {
  FreeUsersActionsTypes,
} from "modules/free-users/store/actions/actions";
import * as freeUsersWorkers from "./free-users";

export default function* (): SagaIterator {
  yield all([
    takeLatest(FreeUsersActionsTypes.FETCH_FREE_USERS, freeUsersWorkers.fetchFreeUsersWorker),
    takeLatest(FreeUsersActionsTypes.FETCH_FREE_ACCOUNT_DETAILS, freeUsersWorkers.fetchSingleAccountWorker),
    takeLatest(FreeUsersActionsTypes.CREATE_FREE_ACCOUNT, freeUsersWorkers.createFreeAccountWorker),
    takeLatest(FreeUsersActionsTypes.EDIT_FREE_ACCOUNT, freeUsersWorkers.editAccountWorker),
    takeEvery(FreeUsersActionsTypes.EDIT_FREE_MODULES, freeUsersWorkers.editFreeModulesWorker),
    takeEvery(FreeUsersActionsTypes.DELETE_FREE_ACCOUNT, freeUsersWorkers.deleteAccountWorker),
    takeEvery(FreeUsersActionsTypes.LOGIN_FREE_ACCOUNT, freeUsersWorkers.loginWithFreeAccountWorker),
    takeEvery(FreeUsersActionsTypes.TOGGLE_ACTIVATION_FREE_ACCOUNT, freeUsersWorkers.toggleActivationFreeAccountWorker),
    takeEvery(FreeUsersActionsTypes.FETCH_CHURN_TYPE_FREE_ACCOUNT, freeUsersWorkers.fetchChurnTypesWorker),
  ]);
}
