import {IntegrationsErrorsState} from "../../types";
import {IntegrationsErrorsActionsTypes} from "../actions/actions";

const initialState: IntegrationsErrorsState = {
  errorsList: [],
  fetchListIsLoading: false,
  totalCount: 0,
  fetchListError: "",
  updateErrorIsLoading: false,
  updateListError: "",
};

const integrationsErrorsReducer = (
  state: IntegrationsErrorsState = initialState,
  action: {type: IntegrationsErrorsActionsTypes; payload: any}
): IntegrationsErrorsState => {
  switch (action.type) {
  case IntegrationsErrorsActionsTypes.FETCH_ERRORS_LIST_SUCCESS:
    return {
      ...state,
      errorsList: action.payload || []
    };
  case IntegrationsErrorsActionsTypes.ERRORS_LIST_SET_IS_LOADING:
    return {
      ...state,
      fetchListIsLoading: action.payload
    };
  case IntegrationsErrorsActionsTypes.ERRORS_LIST_SET_TOTAL_COUNT:
    return {
      ...state,
      totalCount: action.payload ?? 0
    };
  case IntegrationsErrorsActionsTypes.FETCH_ERRORS_LIST_ERROR:
    return {
      ...state,
      fetchListError: action.payload ?? ""
    };
  case IntegrationsErrorsActionsTypes.UPDATE_INTEGRATION_ERROR_IS_LOADING:
    return {
      ...state,
      updateErrorIsLoading: action.payload
    };
  case IntegrationsErrorsActionsTypes.UPDATE_INTEGRATION_ERROR_ERROR:
    return {
      ...state,
      updateListError: action.payload ?? ""
    };
  default:
    return state;
  }
};

export default integrationsErrorsReducer;
