import { SagaIterator } from "redux-saga";
import { put, call } from "redux-saga/effects";
import { push } from "connected-react-router";

import { MediaPartnerBotService } from "modules/media-partner-bot/services";
import {
  fetchSingleBotError,
  fetchSingleBotSuccess,
  shareBotError,
  shareBotSuccess,
  fetchBotsError,
  fetchBotsSuccess,
} from "modules/media-partner-bot/store/actions/actionCreators";
import { FetchSingleBot, ShareBot } from "modules/media-partner-bot/store/actions/actionTypes";

const mediaPartnerBotService = MediaPartnerBotService.getInstance();

export function* fetchBotsWorker(): SagaIterator {
  try {
    const { data } = yield call(mediaPartnerBotService.fetchBots);
    yield put(fetchBotsSuccess(data));
  } catch (e) {
    yield put(fetchBotsError(e.response.data.message));
  }
}

export function* fetchSingleBotWorker({ payload }: FetchSingleBot): SagaIterator {
  try {
    const { data } = yield call(mediaPartnerBotService.fetchSingleBot, payload!.botId);
    yield put(fetchSingleBotSuccess(data));
  } catch (e) {
    if (e.response.status === 404) {
      yield put(push("/shared-bot"));
    }

    yield put(fetchSingleBotError(e.response.data.message));
  }
}

export function* shareBotWorker({ payload }: ShareBot): SagaIterator {
  try {
    const { data } = yield call(mediaPartnerBotService.shareBot, payload!.data);
    yield put(shareBotSuccess(data));
  } catch (e) {
    yield put(shareBotError(e.response.data.message));
  }
}
