import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  input: {
    borderRadius: 5,
    height: 38,
  },
});

export default useStyles;
