import { AxiosResponse } from "axios";

import {Country, Language} from "libs/models";
import { BaseService } from "libs/services";
import { City } from "modules/region-management/models";

type CityData = {
  id: number;
  name: string;
  country_id: number;
};

export class RegionalManagementService extends BaseService {
  private ADMIN_API_ROUTE = this.ADMIN_API_HOST;

  static _instance: RegionalManagementService;

  constructor() {
    super();

    if (RegionalManagementService._instance) {
      throw Error("Unable to create a new instance!");
    }
  }

  static getInstance(): RegionalManagementService {
    if (!RegionalManagementService._instance) {
      RegionalManagementService._instance = new RegionalManagementService();
    }

    return RegionalManagementService._instance;
  }

  public fetchCountries = (): Promise<AxiosResponse<Array<Country>>> => this.get(`${this.ADMIN_API_ROUTE}/country`);

  public createCountry = (data: { name: string }): Promise<AxiosResponse<Country>> => this.post(`${this.ADMIN_API_ROUTE}/country`, data);

  public fetchCities = (): Promise<AxiosResponse<Array<City>>> => this.get(`${this.ADMIN_API_ROUTE}/city`);

  public fetchSingleCity = (cityId: number): Promise<AxiosResponse<City>> => this.get(`${this.ADMIN_API_ROUTE}/city/${cityId}`);

  public createCity = (data: { name: string; country_id: number }): Promise<AxiosResponse<City>> => this.post(`${this.ADMIN_API_ROUTE}/city`, data);

  public removeCity = (cityId: number): Promise<AxiosResponse<{ message: string; city_id: number }>> => this.delete(`${this.ADMIN_API_ROUTE}/city/${cityId}`);

  public editCity = (city: CityData): Promise<AxiosResponse<CityData>> => this.put(`${this.ADMIN_API_ROUTE}/city/${city.id}`, city);

  public fetchLanguages = (): Promise<AxiosResponse<Array<Language>>> => this.get(`${this.ADMIN_API_ROUTE}/company/languages`);
}
