import { BannerPayload } from "modules/broadcast-issue/services/BroadcastService";
import { PutBannerAction } from "./actions";

export enum BannerActions {
  PUT_BANNER = "[BROADCAST-ISSUE] PUT_BANNER",
  PUT_BANNER_SUCCESS = "[BROADCAST-ISSUE] PUT_BANNER_SUCCESS",
  PUT_BANNER_ERROR = "[BROADCAST-ISSUE] PUT_BANNER_ERROR",
  GET_BANNER = "[BROADCAST-ISSUE] GET_BANNER",
  GET_BANNER_SUCCESS = "[BROADCAST-ISSUE] GET_BANNER_SUCCESS",
  GET_BANNER_ERROR = "[BROADCAST-ISSUE] GET_BANNER_ERROR",
  CLEAR_BANNER_ERROR = "[BROADCAST-ISSUE CLEAR_BANNER_ERROR]",
}

export interface PutBanner {
  type: BannerActions.PUT_BANNER;
  payload: PutBannerAction;
}

export interface PutBannerSuccess {
  type: BannerActions.PUT_BANNER_SUCCESS;
  payload: BannerPayload;
}

export interface PutBannerError {
  type: BannerActions.PUT_BANNER_ERROR;
  payload: string;
}

export interface GetBanner {
  type: BannerActions.GET_BANNER;
}

export interface GetBannerSuccess {
  type: BannerActions.GET_BANNER_SUCCESS;
  payload: BannerPayload;
}

export interface GetBannerError {
  type: BannerActions.GET_BANNER_ERROR;
  payload: string;
}

export interface ClearBannerError {
  type: BannerActions.CLEAR_BANNER_ERROR;
}

export type BroadcastIssueActions =
  | PutBanner
  | PutBannerSuccess
  | PutBannerError
  | GetBanner
  | GetBannerSuccess
  | GetBannerError
  | ClearBannerError;
