import { BannerPayload } from "modules/broadcast-issue/services/BroadcastService";
import { BannerActions } from "./actionTypes";

export interface PutBannerAction extends BannerPayload {
  onSuccess: () => void;
}

export const putBanner = (payload: PutBannerAction) => ({
  type: BannerActions.PUT_BANNER,
  payload: payload,
});

export const putBannerSuccess = (payload: BannerPayload) => ({
  type: BannerActions.PUT_BANNER_SUCCESS,
  payload: payload,
});

export const putBannerError = (payload: Error) => ({
  type: BannerActions.PUT_BANNER_ERROR,
  payload: payload,
});

export const getBanner = () => ({
  type: BannerActions.GET_BANNER,
});

export const getBannerSuccess = (payload: BannerPayload) => ({
  type: BannerActions.GET_BANNER_SUCCESS,
  payload: payload,
});

export const getBannerError = (payload: Error) => ({
  type: BannerActions.GET_BANNER_ERROR,
  payload: payload,
});

export const clearBannerError = () => ({
  type: BannerActions.CLEAR_BANNER_ERROR,
});
