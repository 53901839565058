import { SagaIterator } from "redux-saga";
import { all, takeLatest } from "redux-saga/effects";
import { BannerActions } from "../actions/actionTypes";
import { getBanner, setBanner } from "./broadcast";

export default function* (): SagaIterator {
  yield all([
    takeLatest(BannerActions.PUT_BANNER, setBanner),
    takeLatest(BannerActions.GET_BANNER, getBanner),
  ]);
}
