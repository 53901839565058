import React, { Suspense, lazy, useState } from "react";
import { Provider } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { MuiThemeProvider, createTheme } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { history, store } from "libs/redux";
import theme from "libs/ui/theme";
import Loading from "libs/ui/components/layout/Loading";
import {
  AuthMiddleware,
  NonAuthMiddleware,
} from "libs/ui/components/layout/route-middlewares";
import Header from "libs/components/common/Header";
import LeftSideBar from "libs/components/common/LeftSideBar";

import useStyles from "./styles";

const MainLayout = lazy(() => import("modules/root/pages/MainLayout"));
const SignIn = lazy(() => import("modules/auth/components/pages/SignIn"));
const ForgotPassword = lazy(
  () => import("modules/auth/components/pages/ForgotPassword")
);
const AcceptCode = lazy(
  () => import("modules/auth/components/pages/AcceptCode")
);
const Error403Page = lazy(() => import("libs/components/pages/Error403Page"));

const App: React.FC = () => {
  const classes = useStyles();
  const [isDrawerOpened, setIsDropdownOpened] = useState<boolean>(true);

  const toggleDrawer = (): void => setIsDropdownOpened(!isDrawerOpened);

  return (
    <Suspense fallback={<Loading />}>
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <MuiThemeProvider theme={createTheme(theme)}>
            <div className={classes.wrapper}>
              <ConnectedRouter history={history}>
                <Switch>
                  <Route path="/sign-in" exact>
                    <NonAuthMiddleware>
                      <SignIn />
                    </NonAuthMiddleware>
                  </Route>

                  <Route path="/forgot-password" exact>
                    <NonAuthMiddleware>
                      <ForgotPassword />
                    </NonAuthMiddleware>
                  </Route>

                  <Route path="/accept-code" exact>
                    <NonAuthMiddleware>
                      <AcceptCode />
                    </NonAuthMiddleware>
                  </Route>

                  <Route path="/unauthorized">
                    <AuthMiddleware>
                      <Error403Page />
                    </AuthMiddleware>
                  </Route>

                  <Route path="/" exact={false}>
                    <AuthMiddleware>
                      <>
                        <Header
                          isDrawerOpened={isDrawerOpened}
                          toggleDrawer={toggleDrawer}
                        />
                        <div className={classes.container}>
                          <LeftSideBar isDrawerOpened={isDrawerOpened} />

                          <MainLayout />
                        </div>
                      </>
                    </AuthMiddleware>
                  </Route>
                </Switch>
              </ConnectedRouter>
            </div>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </Provider>
    </Suspense>
  );
};

export default App;
