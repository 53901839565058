import { Selector, createSelector } from "reselect";

import { AppState } from "libs/redux";
import { User } from "libs/models";

const getAuthStore = (state: AppState) => state.auth;

export const getIsFetching: Selector<AppState, boolean> = createSelector(
  getAuthStore,
  auth => auth.isFetching
);

export const getErrorMessages: Selector<AppState, Array<string>> = createSelector(
  getAuthStore,
  auth => auth.errorMessages
);

export const getMeOAuth: Selector<AppState, boolean | null> = createSelector(
  getAuthStore,
  auth => auth.isLeadooAdmin
);

export const getOauthUserName: Selector<AppState, string> = createSelector(
  getAuthStore,
  auth => auth.name
);

export const getMeUser: Selector<AppState, User | null> = createSelector(
  getAuthStore,
  auth => auth.user
);
