import React, { useEffect } from "react";

import { AuthService } from "modules/auth/services";
import Loading from "../../Loading";
import { getMeUser } from "modules/auth/store/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMeOAuth,
  fetchMeUser,
} from "modules/auth/store/actions/actionCreators";

interface Props {
  children: React.ReactElement | React.FC;
}
const authService = AuthService.getInstance();

export const AuthMiddleware: React.FC<Props> = ({ children }) => {
  const user = useSelector(getMeUser);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMeUser());
    dispatch(fetchMeOAuth());
  }, [dispatch]);

  useEffect(() => {
    if (!AuthService.getAuthState()) {
      authService.fetchLoginUrl().then((r) => {
        localStorage.setItem("pkceVerifier", r?.data?.pkceVerifier);
        window.location.href = r?.data?.url;
      });
    }
  }, []);

  if (!user) {
    return <Loading />;
  }

  return <>{children}</>;
};
