import { PaginationLinks, PaginationMeta } from "libs/models";
import { Bot } from "modules/media-partner-bot/models";
import { MediaPartnerBotActionTypes } from "./actions";
import * as actionTypes from "./actionTypes";

export const fetchBots = (page?: number): actionTypes.FetchBots => ({
  type: MediaPartnerBotActionTypes.FETCH_BOTS,
  payload: { page },
});

export const fetchBotsSuccess = (payload: {
  items: Array<Bot>;
  meta: PaginationMeta;
  links: PaginationLinks;
}): actionTypes.FetchBotsSuccess => ({
  type: MediaPartnerBotActionTypes.FETCH_BOTS_SUCCESS,
  payload,
});

export const fetchBotsError = (payload: Array<string>): actionTypes.FetchBotsError => ({
  type: MediaPartnerBotActionTypes.FETCH_BOTS_ERROR,
  payload,
});

export const fetchSingleBot = (botId: number): actionTypes.FetchSingleBot => ({
  type: MediaPartnerBotActionTypes.FETCH_SINGLE_BOT,
  payload: {
    botId,
  },
});

export const fetchSingleBotSuccess = (payload: Bot): actionTypes.FetchSingleBotSuccess => ({
  type: MediaPartnerBotActionTypes.FETCH_SINGLE_BOT_SUCCESS,
  payload,
});

export const fetchSingleBotError = (payload: Array<string>): actionTypes.FetchSingleBotError => ({
  type: MediaPartnerBotActionTypes.FETCH_SINGLE_BOT_ERROR,
  payload,
});

export const shareBot = (data: { botId: number; companies: Array<number> }): actionTypes.ShareBot => ({
  type: MediaPartnerBotActionTypes.SHARE_BOT,
  payload: {
    data,
  },
});

export const shareBotSuccess = (payload: { companies: Array<string>; sharedBot: Bot }): actionTypes.ShareBotSuccess => ({
  type: MediaPartnerBotActionTypes.SHARE_BOT_SUCCESS,
  payload,
});

export const shareBotError = (payload: Array<string>): actionTypes.ShareBotError => ({
  type: MediaPartnerBotActionTypes.SHARE_BOT_ERROR,
  payload,
});
