import React from "react";

import App from "./modules/root/pages/App";
import ReactDOM from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import ErrorBoundary from "libs/utils/ErrorBoundary";


import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <ErrorBoundary>
    <App/>
  </ErrorBoundary>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
