import { User, PaginationMeta } from "libs/models";
import { UserManagementActionTypes } from "./actions";
import * as actionTypes from "./actionTypes";
import {FetchUsersQueryParams} from "../../../../../../shared/types/requests";

export const fetchUsers = (params?: FetchUsersQueryParams): actionTypes.FetchUsers => ({
  type: UserManagementActionTypes.FETCH_USERS,
  payload: {
    params,
  },
});

export const fetchUsersSuccess = (payload: { items: Array<User>; meta: PaginationMeta }): actionTypes.FetchUsersSuccess => ({
  type: UserManagementActionTypes.FETCH_USERS_SUCCESS,
  payload,
});

export const fetchUsersError = (payload: UserManagement.Error): actionTypes.FetchUsersError => ({
  type: UserManagementActionTypes.FETCH_USERS_ERROR,
  payload,
});

export const changeAdmin = (userId: number): actionTypes.ChangeAdmin => ({
  type: UserManagementActionTypes.CHANGE_ADMIN,
  payload: userId,
});

export const changeAdminSuccess = (payload: UserManagement.ChangeAdminSuccessPayload): actionTypes.ChangeAdminSuccess => ({
  type: UserManagementActionTypes.CHANGE_ADMIN_SUCCESS,
  payload,
});

export const changeAdminError = (payload: UserManagement.Error): actionTypes.ChangeAdminError => ({
  type: UserManagementActionTypes.CHANGE_ADMIN_ERROR,
  payload,
});

export const fetchCompaniesToAssign = (userId: number): actionTypes.FetchCompaniesToAssign => ({
  type: UserManagementActionTypes.FETCH_COMPANIES_TO_ASSIGN,
  payload: userId,
});

export const fetchCompaniesToAssignSuccess = (payload: User): actionTypes.FetchCompaniesToAssignSuccess => ({
  type: UserManagementActionTypes.FETCH_COMPANIES_TO_ASSIGN_SUCCESS,
  payload,
});

export const fetchCompaniesToAssignError = (payload: UserManagement.Error): actionTypes.FetchCompaniesToAssignError => ({
  type: UserManagementActionTypes.FETCH_COMPANIES_TO_ASSIGN_ERROR,
  payload,
});

export const assignCompanyToUser = (payload: UserManagement.AssignCompanyPayload): actionTypes.AssignCompanyToUser => ({
  type: UserManagementActionTypes.ASSIGN_COMPANY_TO_USER,
  payload,
});

export const assignCompanyToUserSuccess = (payload: User): actionTypes.AssignCompanyToUserSuccess => ({
  type: UserManagementActionTypes.ASSIGN_COMPANY_TO_USER_SUCCESS,
  payload,
});

export const assignCompanyToUserError = (payload: UserManagement.Error): actionTypes.AssignCompanyToUserError => ({
  type: UserManagementActionTypes.ASSIGN_COMPANY_TO_USER_ERROR,
  payload,
});

export const deassignCompanyToUser = (payload: UserManagement.DeassignCompanyPayload): actionTypes.DeassignCompanyToUser => ({
  type: UserManagementActionTypes.DEASSIGN_COMPANY_TO_USER,
  payload,
});

export const deassignCompanyToUserSuccess = (payload: string): actionTypes.DeassignCompanyToUserSuccess => ({
  type: UserManagementActionTypes.DEASSIGN_COMPANY_TO_USER_SUCCESS,
  payload,
});

export const deassignCompanyToUserError = (payload: UserManagement.Error): actionTypes.DeassignCompanyToUserError => ({
  type: UserManagementActionTypes.DEASSIGN_COMPANY_TO_USER_ERROR,
  payload,
});

export const offboardingUser = (payload: { email: string }): actionTypes.OffboardingUser => ({
  type: UserManagementActionTypes.OFFBOARDING_USER,
  payload,
});

export const offboardingUserSuccess = (payload: UserManagement.OffboardUserPayload): actionTypes.OffboardingUserSuccess => ({
  type: UserManagementActionTypes.OFFBOARDING_USER_SUCCESS,
  payload,
});

export const offboardingUserError = (payload: UserManagement.Error): actionTypes.OffboardingUserError => ({
  type: UserManagementActionTypes.OFFBOARDING_USER_ERROR,
  payload,
});
