import { isEmpty } from "lodash";
import { MediaPartnerBot } from "modules/media-partner-bot/models";
import { MediaPartnerBotActionTypes } from "modules/media-partner-bot/store/actions/actions";
import { MediaPartnerBotActions } from "modules/media-partner-bot/store/actions/actionTypes";

const initialState: MediaPartnerBot = {
  isFetching: false,
  errorMessages: [],
  noDataMessage: "",
  bots: [],
  bot: null,
  meta: {
    currentPage: 1,
    itemCount: 0,
    itemsPerPage: 8,
    totalItems: 0,
    totalPages: 1,
  },
  links: {
    first: "",
    next: "",
    last: "",
    previous: "",
  },
};

const mediaPartnerBotReducer = (state: MediaPartnerBot = initialState, action: MediaPartnerBotActions): MediaPartnerBot => {
  switch (action.type) {
  case MediaPartnerBotActionTypes.FETCH_BOTS:
  case MediaPartnerBotActionTypes.FETCH_SINGLE_BOT:
  case MediaPartnerBotActionTypes.SHARE_BOT: {
    return {
      ...state,
      isFetching: true,
      errorMessages: [],
      noDataMessage: "",
    };
  }

  case MediaPartnerBotActionTypes.FETCH_BOTS_SUCCESS:{
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      noDataMessage: isEmpty(action.payload) ? "There are no bots yet!" : "",
      bots: action.payload?.items,
      meta: action.payload?.meta,
      links: action.payload?.links,
    };
  }

  case MediaPartnerBotActionTypes.FETCH_SINGLE_BOT_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      noDataMessage: isEmpty(action.payload) ? "There are no bots yet!" : "",
      bot: action.payload,
    };
  }

  case MediaPartnerBotActionTypes.SHARE_BOT_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      bot: action.payload?.sharedBot,
    };
  }

  case MediaPartnerBotActionTypes.FETCH_BOTS_ERROR:
  case MediaPartnerBotActionTypes.FETCH_SINGLE_BOT_ERROR:
  case MediaPartnerBotActionTypes.SHARE_BOT_ERROR: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [
        ...state.errorMessages,
        ...action.payload,
      ],
    };
  }

  default:
    return state;
  }
};

export default mediaPartnerBotReducer;
